import React from "react"
import Band from "../components/band"

import Layout from "../components/layout"

const NotFound = () => (
  <Layout header="404">
    <Band>
      Page not found.
    </Band>
  </Layout>
)

export default NotFound
